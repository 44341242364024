import propsToCss from '@styled-system/css'
import React, { ReactNode } from 'react'
import Box from '../primitives/Box'
import CardFrame from '../primitives/CardFrame'

const transition = 'all 0.15s ease-out 0s' as const

export const LinkCard = ({
  href,
  target,
  content,
  isFullHeight,
}: {
  content: ReactNode
  href?: string
  target?: string
  isFullHeight?: boolean
}) => {
  const hasLink = href !== undefined

  const linkStyle = propsToCss({
    transition,
    '&:hover .card-frame': {
      cursor: 'pointer',
      borderColor: '#D0D4DE',
      backgroundImage: 'linear-gradient(180deg, #EBECF1 0%, #E5E6ED 100%)',
    },
    '&:hover .arrow': {
      left: 2,
    },
  })

  const frame = (
    <CardFrame
      display="flex"
      flexDirection="column"
      height={isFullHeight ? '100%' : undefined}
      className="card-frame"
    >
      {content}
    </CardFrame>
  )

  return !hasLink ? (
    <>{frame}</>
  ) : href.startsWith('http') ? (
    <Box.a css={linkStyle} href={href} target={target}>
      {frame}
    </Box.a>
  ) : (
    <Box.Link css={linkStyle} to={href} target={target}>
      {frame}
    </Box.Link>
  )
}
